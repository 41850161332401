import React from "react";

import { WarningIcon } from "@chakra-ui/icons";
import { Box, Center } from "@chakra-ui/react";

import ScreenContainer from "../components/ScreenContainer";

import { MAX_HEIGHT } from "../utils/constants";

function ColumnTaxUnavailableScreen() {
  return (
    // This is copied from SDK.tsx
    <Box height="100%" borderRadius="md">
      <Box
        minWidth="xs"
        maxWidth="sm"
        height="full"
        maxHeight={MAX_HEIGHT}
        margin="auto"
      >
        <ScreenContainer renderClose={true}>
          <Box>
            <Center>
              <WarningIcon w={12} h={12} my="5" color="#b60b13" />
            </Center>
            <Center textAlign="center" fontWeight="bold">
              Our services are not available right now due to scheduled
              maintenance.
            </Center>
            <Center>Please come back later.</Center>
          </Box>
        </ScreenContainer>
      </Box>
    </Box>
  );
}

export default ColumnTaxUnavailableScreen;
